import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
     

     <path d="M1534 3710 l-134 -75 140 -3 140 -3 0 -82 c0 -45 -7 -271 -15 -502
-8 -231 -17 -507 -21 -612 l-6 -193 140 0 140 0 6 63 c5 56 12 287 32 1081 l7
259 87 -6 c248 -18 416 -99 480 -232 44 -92 34 -276 -22 -395 -51 -108 -136
-182 -307 -266 l-141 -70 94 2 93 2 57 46 c31 25 68 53 81 62 14 9 23 20 20
24 -3 5 5 11 17 15 261 74 448 284 448 505 0 64 -21 154 -49 208 -30 57 -116
137 -186 171 -136 67 -202 75 -619 76 l-349 0 -133 -75z"/>
<path d="M3090 2934 c-199 -35 -494 -140 -637 -226 -140 -84 -287 -261 -358
-431 l-15 -37 95 0 c68 0 97 4 99 13 34 136 102 274 174 351 74 78 201 152
322 186 235 66 620 10 620 -92 0 -15 4 -18 22 -12 69 22 206 139 195 168 -8
21 -71 51 -137 66 -55 12 -332 23 -380 14z"/>
<path d="M2755 2681 c-63 -23 -110 -47 -155 -80 -19 -14 -36 -26 -37 -26 -2
-1 17 -23 42 -48 58 -61 92 -129 107 -217 l12 -70 149 0 150 0 -6 68 c-9 87
-31 170 -63 237 -32 64 -109 155 -132 155 -9 -1 -39 -9 -67 -19z"/>
<path d="M1128 2075 c4 -75 4 -75 32 -75 47 0 91 12 97 27 3 8 0 26 -7 41 -8
15 -12 35 -11 44 3 22 -29 38 -76 38 l-38 0 3 -75z m72 40 c0 -8 -7 -15 -15
-15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m10 -50 c16 -20 5
-45 -20 -45 -14 0 -20 7 -20 23 0 38 18 48 40 22z"/>
<path d="M1300 2075 l0 -75 55 0 c49 0 55 2 55 21 0 19 -1 19 -16 4 -21 -20
-38 -15 -42 13 -4 24 8 28 26 10 8 -8 12 -9 12 -1 0 24 -12 41 -26 36 -10 -4
-14 3 -14 26 0 36 2 37 35 15 22 -15 23 -14 18 5 -4 18 -13 21 -54 21 l-49 0
0 -75z"/>
<path d="M1466 2134 c-20 -19 -9 -44 34 -74 16 -12 30 -26 30 -31 0 -18 -39
-9 -54 13 -16 22 -16 22 -16 -10 0 -31 2 -32 39 -32 55 0 71 8 71 36 0 17 -12
31 -40 49 -23 14 -38 30 -35 35 9 15 26 12 47 -7 22 -19 32 -12 23 16 -5 17
-15 21 -45 21 -22 0 -45 -7 -54 -16z"/>
<path d="M1600 2136 c0 -8 8 -13 18 -12 14 1 17 -9 20 -61 3 -58 5 -63 25 -63
22 0 23 3 19 65 -5 69 -3 72 30 54 18 -10 20 -9 15 6 -4 9 -7 19 -7 21 0 2
-27 4 -60 4 -45 0 -60 -4 -60 -14z"/>
<path d="M1799 2083 c0 -38 -3 -62 -6 -55 -5 14 -43 17 -43 3 0 -6 10 -17 21
-25 17 -11 27 -12 45 -4 22 10 24 17 24 80 0 61 -2 68 -20 68 -18 0 -20 -7
-21 -67z"/>
<path d="M1880 2136 c0 -9 7 -12 20 -9 19 5 20 0 20 -61 0 -62 1 -66 23 -66
21 0 22 4 20 65 -2 64 -2 65 23 59 19 -5 23 -3 18 10 -8 22 -124 24 -124 2z"/>
<path d="M2040 2075 l0 -75 55 0 c30 0 55 4 55 9 0 15 -15 21 -30 11 -19 -12
-40 -1 -40 21 0 22 11 24 28 7 9 -9 12 -6 12 14 0 21 -4 25 -20 21 -16 -4 -20
0 -20 20 0 34 17 43 41 21 18 -16 19 -16 19 4 0 20 -5 22 -50 22 l-50 0 0 -75z"/>
<path d="M2212 2134 c-16 -11 -22 -25 -22 -53 0 -52 25 -79 75 -83 37 -3 40
-1 42 26 2 26 1 27 -16 12 -40 -36 -69 -6 -56 60 7 38 37 47 54 17 12 -23 25
-11 16 16 -8 26 -60 29 -93 5z"/>
<path d="M2358 2075 c3 -69 5 -75 25 -75 19 0 22 5 21 35 -2 29 2 35 18 35 17
0 20 -5 16 -35 -4 -31 -1 -35 19 -35 22 0 23 3 23 75 0 72 -1 75 -23 75 -20 0
-23 -4 -19 -30 4 -25 1 -30 -17 -30 -17 0 -21 6 -21 30 0 24 -4 30 -22 30 -22
0 -23 -3 -20 -75z"/>
<path d="M2541 2124 c-43 -55 -13 -118 62 -126 36 -4 37 -3 37 27 -1 25 -2 27
-11 13 -26 -45 -79 1 -65 57 9 34 32 42 56 20 19 -18 20 -17 20 8 0 25 -3 27
-39 27 -31 0 -44 -6 -60 -26z"/>
<path d="M2702 2127 c-43 -45 -18 -127 37 -127 31 0 69 26 72 50 4 37 -3 61
-23 80 -29 26 -59 25 -86 -3z m66 -31 c5 -40 -6 -76 -23 -76 -19 0 -28 35 -20
76 8 44 38 44 43 0z"/>
<path d="M2858 2075 c-2 -61 0 -75 13 -78 13 -4 15 4 11 47 -5 46 -4 50 11 37
9 -7 26 -28 38 -47 35 -58 50 -45 48 41 -1 55 -5 75 -15 75 -9 0 -14 -13 -14
-37 l0 -38 -25 38 c-15 21 -34 37 -45 37 -16 0 -19 -9 -22 -75z"/>
<path d="M3030 2075 c0 -61 3 -75 16 -75 12 0 14 9 10 45 -2 25 -1 45 4 45 5
0 21 -20 37 -45 41 -65 53 -59 53 30 0 55 -3 75 -13 75 -9 0 -12 -11 -9 -37
l4 -38 -26 30 c-14 17 -25 33 -26 38 0 4 -11 7 -25 7 -24 0 -25 -2 -25 -75z"/>
<path d="M3205 2075 l0 -75 53 0 c41 0 52 3 52 16 0 13 -3 14 -15 4 -20 -16
-45 -5 -45 20 0 22 25 28 33 8 3 -7 5 3 5 22 0 19 -2 29 -5 23 -9 -23 -33 -14
-33 12 0 28 26 34 49 13 10 -11 12 -9 9 7 -3 16 -12 21 -53 23 l-50 3 0 -76z"/>
<path d="M3375 2125 c-51 -50 -16 -125 58 -125 34 0 37 2 37 28 -1 25 -2 26
-15 9 -31 -42 -69 1 -53 61 10 36 23 40 50 15 17 -15 18 -15 18 10 0 24 -4 27
-35 27 -25 0 -44 -8 -60 -25z"/>
<path d="M3500 2136 c0 -9 7 -12 20 -9 19 5 20 0 20 -61 0 -64 1 -66 25 -66
25 0 25 2 23 60 -2 66 4 79 28 59 14 -12 16 -11 13 6 -4 17 -14 20 -67 23 -49
2 -62 0 -62 -12z"/>
<path d="M3761 2133 c-55 -46 -26 -128 49 -135 39 -3 40 -2 40 26 0 27 -1 28
-13 12 -20 -27 -51 -17 -55 18 -5 38 6 76 20 76 6 0 20 -7 30 -17 22 -20 29
-15 21 15 -7 28 -61 30 -92 5z"/>
<path d="M3915 2125 c-32 -31 -33 -74 -2 -103 50 -47 124 -10 113 58 -6 38
-37 70 -68 70 -10 0 -30 -11 -43 -25z m60 -51 c0 -37 -4 -49 -16 -52 -21 -4
-30 41 -17 80 15 43 33 28 33 -28z"/>
<path d="M4070 2075 c0 -59 3 -75 14 -75 11 0 13 9 9 37 -7 44 12 38 22 -7 5
-23 12 -29 29 -28 16 2 22 7 19 19 -3 10 0 26 6 36 9 15 10 12 11 -19 0 -33 3
-38 23 -38 22 0 23 3 21 68 -1 37 -3 70 -3 75 -1 4 -9 7 -19 7 -13 0 -24 -14
-36 -42 l-17 -43 -19 43 c-13 29 -25 42 -40 42 -18 0 -20 -6 -20 -75z"/>
<path d="M3667 2034 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>
<path d="M2020 1848 c1 -62 19 -163 44 -240 107 -327 446 -548 839 -548 117 0
199 19 326 74 79 35 288 162 279 171 -2 2 -31 -4 -65 -15 -187 -58 -477 -73
-625 -32 -288 79 -474 263 -540 535 l-21 87 -119 0 -118 0 0 -32z"/>
<path d="M1620 1804 c0 -113 -38 -229 -88 -272 l-27 -22 215 1 c118 0 226 3
240 6 l26 5 -21 63 -20 63 -50 6 c-27 3 -65 6 -84 6 l-35 0 31 31 c35 33 69
97 79 147 l7 32 -137 0 -136 0 0 -66z"/>
<path d="M2611 1819 c-36 -53 -111 -124 -148 -142 -24 -11 -28 -23 -14 -46 19
-30 301 128 380 213 l24 26 -104 0 -104 -1 -34 -50z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
